// create a React functional component
const React = require('react');

const redirectSetupIntent = (props) => {
  const { data } = props;
  setTimeout(() => {
    window.location.replace(data.initPoint);
  }, 2000);
};

const redirectPaymentIntent = (props) => {
  const { data, isWebview } = props;
  if (isWebview && window.MobileWebKit) {
    window.MobileWebKit.navigation.push(data?.deepLink, {
      replace: true,
    });
    return;
  }
  window.location.replace(data.initPoint);
};

const redirectPreference = (props) => {
  const { button } = props;
  const { checkoutURL } = button;

  window.location.replace(checkoutURL);
};

const useViewController = (props) => {
  const { data } = props;
  React.useEffect(() => {
    if (data.type === 'payment-intent') {
      redirectPaymentIntent(props);
    }
    if (data.type === 'preference') {
      redirectPreference(props);
    }
    if (data.type === 'setup-intent') {
      redirectSetupIntent(props);
    }
  }, [data.type, props]);
};

module.exports = { useViewController, redirectPaymentIntent, redirectPreference, redirectSetupIntent };
