const Time = require('./icons/Time');
const Generic = require('./icons/Generic');
const User = require('./icons/User');

const getErrorAssetsByData = (i18n, errorData) => {
  const cases = {
    generic: {
      title: i18n.gettext('Tuvimos un problema'),
      description: '',
      icon: Generic,
    },
    not_active: {
      title: i18n.gettext('Lo que quieres pagar no está activo.'),
      description: '',
      icon: Time,
    },
    collector_equal_payer: {
      title: i18n.gettext('Tuvimos un problema'),
      description: i18n.gettext('No puedes pagarte a ti mismo.'),
      icon: User,
    },
    expiration_date_from: {
      title: i18n.gettext('Lo que quieres comprar aún no está disponible'),
      description: 'Intenta de nuevo a partir del {0}.',
      icon: Time,
      date: errorData.date,
    },
    expiration_date_to: {
      title: i18n.gettext('Lo que querías pagar ya no está disponible.'),
      description: 'Contáctate con el vendedor para pagar.',
      icon: Time,
      date: errorData.date,
    },
  };

  return cases[errorData.cause] || cases.generic;
};

module.exports = { getErrorAssetsByData };
