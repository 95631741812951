const React = require('react');
const dayjs = require('dayjs');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const { Title, Text } = require('@andes/typography');
const { getErrorAssetsByData } = require('./helpers');

const formattedDateAndTime = (date) => dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ').format('DD/MM/YYYY HH:mm');

const Error = ({ i18n, errorData }) => {
  const [show, setShow] = React.useState(false);
  const { title, description, icon, date } = getErrorAssetsByData(i18n, errorData);
  const dateTime = formattedDateAndTime(date);

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    show && (
    <div className="error">
      <div className="error__icon">{React.createElement(icon)}</div>
      <Title component="h1" size="s" className="error__title">{title || ''}</Title>
      <Text size="s" className="error__subtitle">
        {dateTime ? i18n.gettext(description, dateTime) : i18n.gettext(description) || ''}
      </Text>
    </div>)
  );
};

Error.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.objectOf(PropTypes.func),
  }),
  errorData: PropTypes.shape({
    cause: PropTypes.string,
    date: PropTypes.string,
  }),
};

module.exports = injectI18n(Error);
