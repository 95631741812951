const React = require('react');
const Head = require('nordic/head');
const PropTypes = require('prop-types');
const useI18n = require('nordic/i18n/useI18n');

const CrawlerHead = (props) => {
  const { i18n } = useI18n(props);
  const { crawlerData } = props;
  const isFromInstagram = crawlerData.fromInstagram;

  return (
    <Head>
      <meta property="og:url" content={crawlerData.url} />
      {!isFromInstagram && <meta property="og:site_name" content={crawlerData.site_name} />}
      {!isFromInstagram && <meta property="og:title" content={crawlerData.title} />}
      {!isFromInstagram && <meta property="og:description" content={crawlerData.description} />}
      {!isFromInstagram && <meta property="og:type" content="article" />}
      {!isFromInstagram && <meta property="og:image" content={crawlerData.image} />}
      {!isFromInstagram && crawlerData.width && <meta property="og:image:width" content={crawlerData.width} />}
      {!isFromInstagram && crawlerData.height && <meta property="og:image:height" content={crawlerData.height} />}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@mercadopago" />
      <meta name="twitter:title" content={crawlerData.title} />
      <meta name="twitter:description" content={crawlerData.description} />
      <meta name="twitter:creator" content="@mercadopago" />
      <meta name="twitter:image" content={crawlerData.image} />
      <meta httpEquiv="cache-control" content="no-cache" />
      <meta httpEquiv="pragma" content="no-cache" />

      <title>{i18n.gettext('Botón y link de pago')}</title>
    </Head>
  );
};

CrawlerHead.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  crawlerData: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    site_name: PropTypes.string,
    fromInstagram: PropTypes.bool,
  }),
};

module.exports = CrawlerHead;
