/* eslint-disable react/prop-types */
const React = require('react');
const Script = require('nordic/script');
const Page = require('nordic/page');
const CrawlerHead = require('../../components/CrawlerHead');
const { useViewController } = require('./hooks/useViewController');

const View = (props) => {
  const { crawlerData } = props;
  const analytics = {
    section: 'payment-link',
    page: '/v1/redirect',
  };

  useViewController(props);

  return (
    <Page
      name="redirect"
      state={props}
      analytics={analytics}
    >
      <CrawlerHead crawlerData={crawlerData} />
      <Script src="vendor.js" />
    </Page>
  );
};

module.exports = View;
