/* eslint-disable react/prop-types */
const React = require('react');
const Head = require('nordic/head');
const Script = require('nordic/script');
const Page = require('nordic/page');
const useI18n = require('nordic/i18n/useI18n');
const ErrorInfo = require('../../components/Error');

const View = (props) => {
  const { i18n } = useI18n();
  const { error } = props;
  return (
    <Page
      name="error"
      state={props}
    >
      <Head>
        <title>{i18n.gettext('Algo salió mal')}</title>
      </Head>
      <ErrorInfo errorData={error} />
      <Script src="vendor.js" />
    </Page>
  );
};

module.exports = View;
